export default function Home () {
	const preText = "<"
	const postText = "/>"
	return(
		<div className="homeNav">
			<div>
				<h2 className="link">PROJECTS</h2>
			</div>
			<div>
				<h2 className="link">ABOUT</h2>
			</div>
		</div>
	)
}